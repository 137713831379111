import {
  READ_TICKET,
  READ_TICKET_MESSAGE,
  CREATE_TICKET,
  DETAIL_TICKET,
  UPDATE_TICKET,
  DELETE_TICKET,
} from 'shared/constants/ActionTypes';

const initialState = {
  ticketList: [],
  ticketMsgList: {},
  selectedTicket: null,
};

const ticketReducer = (state = initialState, action) => {
  switch (action.type) {
    case READ_TICKET:
      return {
        ...state,
        ticketList: action.payload,
        selectedTicket: null,
        ticketMsgList: {},
      };
    case READ_TICKET_MESSAGE:
      return {
        ...state,
        ticketMsgList: action.payload,
      };
    case CREATE_TICKET:
      return {
        ...state,
        selectedTicket: null,
        ticketMsgList: {},
      };
    case DETAIL_TICKET:
      return {
        ...state,
        selectedTicket: action.payload,
      };
    case UPDATE_TICKET:
      return {
        ...state,
        selectedTicket: null,
        ticketMsgList: {},
      };
    case DELETE_TICKET:
      return {
        ...state,
        selectedTicket: null,
        ticketMsgList: {},
      };

    default:
      return state;
  }
};
export default ticketReducer;
