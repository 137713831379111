import React from 'react';

import PropTypes from 'prop-types';

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const BaseAlert = React.forwardRef(function BaseAlert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} {...props} />;
});

const Alert = ({onHide}, ref) => {
  const [config, setConfig] = React.useState({
    type: 'success',
    msg: '',
    show: false,
  });

  const handleClose = () => {
    setConfig((prev) => ({...prev, show: false}));
    if (typeof onHide === 'function') onHide();
  };

  React.useImperativeHandle(ref, () => ({
    show: (newConfig) => {
      setConfig({
        ...newConfig,
        show: true,
      });
    },
    hide: handleClose,
  }));

  return (
    <Snackbar
      open={config.show}
      anchorOrigin={{vertical: 'top', horizontal: 'right'}}
      autoHideDuration={3000}
      onClose={handleClose}
    >
      <BaseAlert
        onClose={handleClose}
        severity={config.type}
        color={config.type === 'success' ? 'info' : config.type}
        sx={{
          width: '100%',
          alignItems: 'center',
          '.MuiAlert-action': {paddingTop: 0},
        }}
      >
        {config.msg}
      </BaseAlert>
    </Snackbar>
  );
};

export default React.forwardRef(Alert);

Alert.propTypes = {
  onHide: PropTypes.func,
};
