import React from 'react';

import {useLocation} from 'react-router-dom';

import AppPublicHeader from '../AppLayout/components/AppPublicHeader';
import AppPublicFooter from '../AppLayout/components/AppPublicFooter';
import AppErrorBoundary from '../AppErrorBoundary';
import {useAuthUser} from '../../utility/AuthHooks';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import AppSuspense from '../AppSuspense';
import generateRoutes from '../../utility/RouteGenerator';
import {
  anonymousStructure,
  authorizedStructure,
  unAuthorizedStructure,
} from '../../../pages';
import {publicRouteConfig} from 'pages/publicPages';

const AppContentView = ({sxStyle}) => {
  const {user, isAuthenticated} = useAuthUser();
  const {pathname} = useLocation();

  const isPublicPage = publicRouteConfig.find((item) => item.path === pathname);

  return (
    <>
      {(isPublicPage || !isAuthenticated) && <AppPublicHeader />}

      <Box
        sx={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          ...(isPublicPage || !isAuthenticated
            ? {mt: {xs: '50px', sm: '64px'}}
            : {p: {xs: 5, md: 7.5, xl: 12.5}}),
          ...sxStyle,
        }}
        className='app-content'
      >
        <AppSuspense>
          <AppErrorBoundary>
            {generateRoutes({
              isAuthenticated: isAuthenticated,
              userRole: user?.role,
              unAuthorizedStructure,
              authorizedStructure,
              anonymousStructure,
            })}
          </AppErrorBoundary>
        </AppSuspense>
      </Box>

      {(isPublicPage || !isAuthenticated) && <AppPublicFooter />}
    </>
  );
};

export default AppContentView;

AppContentView.propTypes = {
  sxStyle: PropTypes.object,
};
