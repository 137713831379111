import {
  READ_CLAIM,
  CREATE_CLAIM,
  DETAIL_CLAIM,
  UPDATE_CLAIM,
  DELETE_CLAIM,
} from 'shared/constants/ActionTypes';

const initialState = {
  claimList: [],
  selectedClaim: null,
};

const claimReducer = (state = initialState, action) => {
  switch (action.type) {
    case READ_CLAIM:
      return {
        ...state,
        claimList: action.payload,
        selectedClaim: null,
      };
    case CREATE_CLAIM:
      return {
        ...state,
        selectedClaim: null,
      };
    case DETAIL_CLAIM:
      return {
        ...state,
        selectedClaim: action.payload,
      };
    case UPDATE_CLAIM:
      return {
        ...state,
        selectedClaim: null,
      };
    case DELETE_CLAIM:
      return {
        ...state,
        selectedClaim: null,
      };

    default:
      return state;
  }
};
export default claimReducer;
