import * as yup from 'yup';

const claimValidationSchema = yup.object({
  order_id: yup.object().required('Field wajib diisi'),
  type: yup.string().required('Field wajib diisi'),
  description: yup.string().required('Field wajib diisi'),
  attachments: yup
    .array()
    .nullable()
    .required('Field wajib diisi')
    .min(1, 'Minimal 1 bukti bayar'),
});

export default claimValidationSchema;
