import {
  READ_PAYMENT,
  CREATE_PAYMENT,
  DETAIL_PAYMENT,
  UPDATE_PAYMENT,
  DELETE_PAYMENT,
} from 'shared/constants/ActionTypes';

const initialState = {
  paymentList: [],
  selectedPayment: null,
};

const paymentReducer = (state = initialState, action) => {
  switch (action.type) {
    case READ_PAYMENT:
      return {
        ...state,
        paymentList: action.payload,
        selectedPayment: null,
      };
    case CREATE_PAYMENT:
      return {
        ...state,
        selectedPayment: null,
      };
    case DETAIL_PAYMENT:
      return {
        ...state,
        selectedPayment: action.payload,
      };
    case UPDATE_PAYMENT:
      return {
        ...state,
        selectedPayment: null,
      };
    case DELETE_PAYMENT:
      return {
        ...state,
        selectedPayment: null,
      };

    default:
      return state;
  }
};
export default paymentReducer;
