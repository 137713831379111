import React from 'react';

import PropTypes from 'prop-types';
import {DateRangePicker} from 'react-date-range';
import moment from 'moment';

import {
  Box,
  FormControl,
  TextField,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Stack,
} from '@mui/material';
import {grey} from '@mui/material/colors';

const DateBetweenSelect = (props) => {
  const {item = {}, applyValue} = props;

  const INITIAL_VALUE = {
    startDate: item?.value?.start_date
      ? new Date(item.value.start_date)
      : new Date(),
    endDate: item?.value?.end_date ? new Date(item.value.end_date) : new Date(),
    key: 'selection',
  };

  const [open, setOpen] = React.useState(false);
  const [selectionRange, setSelectionRange] = React.useState(INITIAL_VALUE);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleReset = () => {
    setSelectionRange(INITIAL_VALUE);
    applyValue({...item, value: null});
    setOpen(false);
  };

  const handleApply = () => {
    setOpen(false);
    applyValue({
      ...item,
      value: {
        start_date: moment(selectionRange.startDate).format('yyyy-MM-DD'),
        end_date: moment(selectionRange.endDate).format('yyyy-MM-DD'),
      },
    });
  };

  const handleSelect = ({selection}) => {
    setSelectionRange(selection);
  };

  return (
    <>
      <Stack spacing={3} direction={'row'}>
        <FormControl fullWidth>
          <Box
            component='label'
            sx={{
              fontSize: 12,
              display: 'block',
              textAlign: 'left',
              marginBottom: 2,
            }}
          >
            Tanggal Awal
          </Box>

          <TextField
            variant='outlined'
            value={
              item?.value?.start_date
                ? moment(item.value.start_date).format('DD MMM yyyy')
                : ''
            }
            onClick={handleOpen}
            placeholder='Pilih Tanggal Awal'
            sx={{
              '.MuiInputBase-root': {borderRadius: 1},
              '.MuiOutlinedInput-notchedOutline': {borderColor: grey[300]},
            }}
          />
        </FormControl>
        <FormControl fullWidth>
          <Box
            component='label'
            sx={{
              fontSize: 12,
              display: 'block',
              textAlign: 'left',
              marginBottom: 2,
            }}
          >
            Tanggal Akhir
          </Box>

          <TextField
            variant='outlined'
            value={
              item?.value?.end_date
                ? moment(item.value.end_date).format('DD MMM yyyy')
                : ''
            }
            onClick={handleOpen}
            placeholder='Pilih Tanggal Akhir'
            sx={{
              '.MuiInputBase-root': {borderRadius: 1},
              '.MuiOutlinedInput-notchedOutline': {borderColor: grey[300]},
            }}
          />
        </FormControl>
      </Stack>

      <Dialog onClose={handleClose} open={open}>
        <DialogContent sx={{px: 4, pb: 2}}>
          <DateRangePicker ranges={[selectionRange]} onChange={handleSelect} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleReset}>Reset</Button>
          <Button onClick={handleApply}>Apply</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const DateBetweenFilter = [
  {
    label: 'Equal',
    value: 'equal',
    getApplyFilterFn: (filterItem) => {
      if (!filterItem.field || !filterItem.value || !filterItem.operator) {
        return null;
      }

      return (params) => {
        return params.value;
      };
    },
    InputComponent: DateBetweenSelect,
    getValueAsString: (value) => value,
    isFilterUseLabel: false,
  },
];

export default DateBetweenFilter;

DateBetweenSelect.propTypes = {
  item: PropTypes.object,
  applyValue: PropTypes.func,
};
