import {
  READ_ORDER,
  CREATE_ORDER,
  DETAIL_ORDER,
  UPDATE_ORDER,
  DELETE_ORDER,
  DETAIL_ORDER_STATUS,
  DETAIL_ESTIMATE_PRICE,
} from 'shared/constants/ActionTypes';

const initialState = {
  orderList: [],
  selectedOrder: null,
  selectedOrderStatus: null,
  selectedEstimatePrice: null,
};

const orderReducer = (state = initialState, action) => {
  switch (action.type) {
    case READ_ORDER:
      return {
        ...state,
        orderList: action.payload,
        selectedOrder: null,
        selectedOrderStatus: null,
      };
    case CREATE_ORDER:
      return {
        ...state,
        selectedOrder: null,
        selectedOrderStatus: null,
      };
    case DETAIL_ORDER:
      return {
        ...state,
        selectedOrder: action.payload,
        selectedOrderStatus: null,
      };
    case DETAIL_ORDER_STATUS:
      return {
        ...state,
        selectedOrderStatus: action.payload,
      };
    case DETAIL_ESTIMATE_PRICE:
      return {
        ...state,
        selectedEstimatePrice: action.payload,
      };
    case UPDATE_ORDER:
      return {
        ...state,
        selectedOrder: null,
        selectedOrderStatus: null,
      };
    case DELETE_ORDER:
      return {
        ...state,
        selectedOrder: null,
        selectedOrderStatus: null,
      };

    default:
      return state;
  }
};
export default orderReducer;
