import React from 'react';

import PropsTypes from 'prop-types';
import {useField} from 'formik';

import {
  FormControl,
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
} from '@mui/material';

const CheckboxField = ({label, isVerticalLabel, ...props}) => {
  const [field, meta, helper] = useField(props);
  const errorText = meta.error && meta.touched ? meta.error : '';

  const handleOnChange = (event) => {
    helper.setValue(event.target.checked);
  };

  return (
    <FormControl sx={{flex: 1, width: '100%'}} error={!!errorText}>
      {label && isVerticalLabel && <FormLabel>{label}</FormLabel>}

      <FormGroup>
        <FormControlLabel
          control={<Checkbox {...props} checked={field.value} />}
          label={isVerticalLabel ? '' : label}
          onChange={handleOnChange}
        />
      </FormGroup>

      {!!errorText && <FormHelperText sx={{ml: 0}}>{errorText}</FormHelperText>}
    </FormControl>
  );
};

export default CheckboxField;

CheckboxField.propTypes = {
  label: PropsTypes.string,
  isVerticalLabel: PropsTypes.bool,
};
