import React from 'react';

import {useField} from 'formik';

import PropTypes from 'prop-types';

import {
  FormControl,
  TextField as BaseTextField,
  Stack,
  FormGroup,
  FormControlLabel,
  Checkbox,
  InputAdornment,
} from '@mui/material';
import {grey} from '@mui/material/colors';
import {NumericFormat} from 'components';

const TextFieldCheckbox = ({
  sx,
  checkboxProps,
  isHorizontal,
  startAdornment,
  endAdornment,
  type,
  ...props
}) => {
  const [field, meta, helper] = useField(props);
  const errorText = meta.error && meta.touched ? meta.error : '';

  const isNumber = type === 'number';

  const defaultValue = React.useRef(props?.value ?? field?.value ?? '');

  const [isEnable, setIsEnable] = React.useState(false);

  React.useEffect(() => {
    if (field.value) {
      setIsEnable(true);
    }
  }, []);

  const handleToogleCheckbox = (event) => {
    setIsEnable((prev) => !prev);

    if (typeof checkboxProps.onChange === 'function') {
      checkboxProps.onChange(event);
    }

    helper.setValue(defaultValue.current);
  };

  return (
    <Stack
      sx={{flex: 1, width: '100%'}}
      spacing={1}
      direction={isHorizontal ? 'row' : 'column'}
    >
      <FormGroup>
        <FormControlLabel
          control={<Checkbox />}
          onChange={handleToogleCheckbox}
          checked={isEnable}
          {...checkboxProps}
        />
      </FormGroup>
      <FormControl sx={isHorizontal ? {flex: 1} : {}}>
        <BaseTextField
          {...field}
          {...props}
          InputProps={{
            ...(isNumber ? {inputComponent: NumericFormat} : {}),
            startAdornment: startAdornment ? (
              <InputAdornment position='start'>{startAdornment}</InputAdornment>
            ) : null,
            endAdornment: endAdornment ? (
              <InputAdornment position='end'>{endAdornment}</InputAdornment>
            ) : null,
          }}
          value={props?.value ?? field?.value ?? ''}
          disabled={!isEnable}
          size='medium'
          sx={{
            backgroundColor: isEnable ? '#fff' : grey[100],
            '.MuiInputBase-root': {borderRadius: 1},
            '.MuiOutlinedInput-notchedOutline': {
              borderColor: grey[300],
            },
            ...sx,
          }}
          helperText={errorText}
          error={!!errorText}
        />
      </FormControl>
    </Stack>
  );
};

export default TextFieldCheckbox;

TextFieldCheckbox.propTypes = {
  sx: PropTypes.object,
  checkboxProps: PropTypes.object,
  isHorizontal: PropTypes.bool,
  value: PropTypes.string,
  type: PropTypes.string,
  endAdornment: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  startAdornment: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
};
