import React from 'react';

const Signin = React.lazy(() => import('./Signin'));
const Signup = React.lazy(() => import('./Signup'));
const ResetPassword = React.lazy(() => import('./ResetPassword'));
const EmailVerification = React.lazy(() => import('./EmailVerification'));
const ForgetPassword = React.lazy(() => import('./ForgetPassword'));

import routeUrl from 'shared/constants/RouteUrl';

export const authRouteConfig = [
  {
    path: routeUrl.signin,
    element: <Signin />,
  },
  {
    path: routeUrl.signup,
    element: <Signup />,
  },
  {
    path: routeUrl.resetPassword,
    element: <ResetPassword />,
  },
  {
    path: routeUrl.emailVerification,
    element: <EmailVerification />,
  },
  {
    path: routeUrl.forgetPassword,
    element: <ForgetPassword />,
  },
];
