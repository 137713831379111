export const routeName = {
  // public page
  home: 'Landing',
  signin: 'Sign In',
  signup: 'Sign Up',
  resetPassword: 'Reset Password',
  emailVerification: 'Email Verification',
  forgetPassword: 'Forget Password',
  tracking: 'Tracking',
  trackingResult: 'Tracking Result',
  rateCheck: 'Rate Check',

  // error page
  error401: '401',
  error403: '403',
  error404: '404',
  error500: '500',
  error503: '503',
  comingSoon: 'Coming Soon',
  maintenance: 'Maintenance',

  // authorized page
  dashboard: 'Dashboard',
  order: 'Order',
  addOrder: 'Add Order',
  detailOrder: 'Detail Order',
  payment: 'Payment',
  detailPayment: 'Detail Payment',
  claim: 'Claim',
  addClaim: 'Add Claim',
  editClaim: 'Edit Claim',
  detailClaim: 'Detail Claim',
  history: 'History',
  account: 'Account',
};

const routeUrl = {
  // public page
  home: '/',
  signin: '/signin',
  signup: '/signup',
  resetPassword: '/resetpassword',
  emailVerification: '/email-verification',
  forgetPassword: '/forget-password',
  tracking: '/tracking',
  trackingResult: '/tracking-result',
  rateCheck: '/rate-check',
  privacy: '/privacy-policy',
  term: '/terms-and-conditions',

  // error page
  error401: '/401',
  error403: '/403',
  error404: '/404',
  error500: '/500',
  error503: '/503',
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',

  // authorized page
  dashboard: '/dashboard',
  order: '/order',
  addOrder: '/order/add',
  editOrder: '/order/edit',
  detailOrder: '/order/detail',
  payment: '/payment',
  detailPayment: '/payment',
  editPayment: '/payment',
  claim: '/claim',
  addClaim: '/claim/add',
  editClaim: '/claim/edit',
  detailClaim: '/claim/detail',
  ticket: '/ticket',
  addTicket: '/ticket/add',
  detailTicket: '/ticket/detail',
  history: '/history',
  account: '/account',
};

export default routeUrl;
