import React from 'react';

import {useDispatch} from 'react-redux';
import PropTypes from 'prop-types';

import {onGetRouteData} from 'redux/actions';

import {ROUTE_DELIVERY} from 'shared/constants/AppDataConst';
import FilterSelectInput from 'components/atoms/FilterSelectInput';

const FilterRoutetInput = (props) => {
  const dispatch = useDispatch();

  const [defaultRouteyOpt, setDefaultRouteOpt] = React.useState([]);

  React.useEffect(() => {
    const getDefaultRouteOptions = async () => {
      if (defaultRouteyOpt.length === 0) {
        const response = await dispatch(onGetRouteData());
        const {rows} = response?.data ?? {};

        setDefaultRouteOpt(
          rows.map((item) => ({
            label: `${item.origin.name} - ${item.destination.name}`,
            value: item.id,
          })),
        );
      }
    };

    getDefaultRouteOptions();
  }, []);

  const handleFetchRoute = async (value, callback) => {
    const route = await dispatch(onGetRouteData({search: value}));

    callback(
      (route?.data?.rows ?? []).map((item) => ({
        label: `${item.origin.name} - ${item.destination.name}`,
        value: item.id,
      })),
    );
  };

  const filterDatas = (data, inputValue) => {
    return data.filter((i) =>
      i.label.toLowerCase().includes(inputValue.toLowerCase()),
    );
  };

  const loadOptions = (inputValue, callback) => {
    if (inputValue.length === 0) {
      callback([]);
      return;
    }

    handleFetchRoute(inputValue, (data) =>
      callback(filterDatas(data ?? [], inputValue)),
    );
  };

  return (
    <FilterSelectInput
      {...props}
      loadOptions={loadOptions}
      defaultOptions={defaultRouteyOpt}
      value={props?.item?.value ?? null}
      isAsync={true}
      cacheOptions
    />
  );
};

// This custom grid data filter for grid data pro
const RouteDeliveryFilter = [
  {
    label: 'Equal',
    value: 'equal',
    getApplyFilterFn: (filterItem) => {
      if (!filterItem.field || !filterItem.value || !filterItem.operator) {
        return null;
      }

      return (params) => {
        return params.value === filterItem.value;
      };
    },
    InputComponent: FilterRoutetInput,
    InputComponentProps: {options: ROUTE_DELIVERY},
    getValueAsString: (value) => value,
  },
];

export default RouteDeliveryFilter;

FilterRoutetInput.propTypes = {
  item: PropTypes.object,
};
