import React, {createContext, useContext, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';
// import isAfter from 'date-fns/isAfter';
import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  SHOW_MESSAGE,
} from 'shared/constants/ActionTypes';
import {
  REGISTER,
  LOGIN,
  PROFILE,
  LOGOUT,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  UPDATE_PROFILE,
  CHANGE_PASSWORD,
  // REFRESH_TOKEN,
} from 'shared/constants/ApiUrl';
import jwtAxios, {setAuthToken} from './index';

const JWTAuthContext = createContext();
const JWTAuthActionsContext = createContext();

export const useJWTAuth = () => useContext(JWTAuthContext);

export const useJWTAuthActions = () => useContext(JWTAuthActionsContext);

const JWTAuthAuthProvider = ({children}) => {
  const [firebaseData, setJWTAuthData] = useState({
    user: null,
    isAuthenticated: false,
    isLoading: true,
    redirectPath: null,
    redirectParams: null,
  });

  const dispatch = useDispatch();

  useEffect(() => {
    const getAuthUser = async () => {
      let token = localStorage.getItem('token');
      // const token_expires = localStorage.getItem('token_expires');
      // const refresh_token = localStorage.getItem('refresh_token');

      if (!token) {
        setJWTAuthData({
          user: undefined,
          isLoading: false,
          isAuthenticated: false,
        });
        return;
      }

      // if (isAfter(new Date(), new Date(token_expires))) {
      //   const {data} = await jwtAxios.post(REFRESH_TOKEN, {refresh_token});

      //   localStorage.setItem('token', data.data.key.token);
      //   localStorage.setItem('token_expires', data.data.key.expires);
      //   localStorage.setItem('refresh_token', data.data.key.refresh_token);

      //   token = data.data.key.token;
      //   return;
      // }

      setAuthToken(token);
      jwtAxios
        .get(PROFILE)
        .then(({data}) =>
          setJWTAuthData({
            user: data.data,
            isLoading: false,
            isAuthenticated: true,
          }),
        )
        .catch(() =>
          setJWTAuthData({
            user: undefined,
            isLoading: false,
            isAuthenticated: false,
          }),
        );
    };

    getAuthUser();
  }, []);

  const signInUser = async (
    {email, password},
    {redirectPath, redirectParams},
  ) => {
    dispatch({type: FETCH_START});
    try {
      const res = await jwtAxios.post(LOGIN, {email, password});

      if (res.data.status === 1) {
        localStorage.setItem('token', res.data.data.key.token);
        localStorage.setItem('token_expires', res.data.data.key.expires);
        localStorage.setItem('refresh_token', res.data.data.key.refresh_token);

        setAuthToken(res.data.data.key.token);
        const profileRes = await jwtAxios.get(PROFILE);
        setJWTAuthData({
          user: profileRes.data.data,
          isAuthenticated: true,
          isLoading: false,
          redirectPath,
          redirectParams,
        });

        dispatch({type: FETCH_SUCCESS});
      } else {
        dispatch({
          type: FETCH_ERROR,
          payload: res.data.message || 'Something went wrong',
        });
      }

      return res.data;
    } catch (error) {
      setJWTAuthData({
        ...firebaseData,
        isAuthenticated: false,
        isLoading: false,
      });
      dispatch({
        type: FETCH_ERROR,
        payload: error?.response?.data?.message || 'Something went wrong',
      });

      return error.response.data;
    }
  };

  const signUpUser = async ({
    name,
    email,
    password,
    password_confirmation,
    company_name,
    address,
    phone_number,
  }) => {
    dispatch({type: FETCH_START});
    try {
      const res = await jwtAxios.post(REGISTER, {
        name,
        email,
        password,
        password_confirmation,
        company_name,
        address,
        phone_number,
      });

      if (res.data.status === 1) {
        dispatch({
          type: SHOW_MESSAGE,
          payload: 'Register berhasil',
          payloadType: 'success',
        });
      } else {
        dispatch({
          type: FETCH_ERROR,
          payload: res.data.message || 'Something went wrong',
        });
      }

      return res.data;
    } catch (error) {
      setJWTAuthData({
        ...firebaseData,
        isAuthenticated: false,
        isLoading: false,
      });
      dispatch({
        type: FETCH_ERROR,
        payload: error?.response?.data?.message || 'Something went wrong',
      });

      return error.response.data;
    }
  };

  const logout = async () => {
    const token = localStorage.getItem('token');
    await jwtAxios.post(LOGOUT, {token});

    localStorage.removeItem('token');
    localStorage.removeItem('token_expires');
    localStorage.removeItem('refresh_token');

    setAuthToken();
    setJWTAuthData({
      user: null,
      isLoading: false,
      isAuthenticated: false,
    });
  };

  const forgotPassword = async ({email}) => {
    dispatch({type: FETCH_START});
    try {
      const res = await jwtAxios.post(FORGOT_PASSWORD, {email});

      if (res.data.status === 1) {
        dispatch({
          type: SHOW_MESSAGE,
          payload: res.data.message,
          payloadType: 'info',
        });
      } else {
        dispatch({
          type: FETCH_ERROR,
          payload: res.data.message || 'Something went wrong',
        });
      }

      return res.data;
    } catch (error) {
      dispatch({
        type: FETCH_ERROR,
        payload: error?.response?.data?.message || 'Something went wrong',
      });

      return error.response.data;
    }
  };

  const resetPassword = async ({password, password_confirmation}, token) => {
    dispatch({type: FETCH_START});
    try {
      setAuthToken(token);

      const res = await jwtAxios.put(RESET_PASSWORD, {
        password,
        password_confirmation,
      });

      if (res.data.status === 1) {
        dispatch({
          type: SHOW_MESSAGE,
          payload: res.data.message,
          payloadType: 'success',
        });
      } else {
        dispatch({
          type: FETCH_ERROR,
          payload: res.data.message || 'Something went wrong',
        });
      }

      setAuthToken();

      return res.data;
    } catch (error) {
      dispatch({
        type: FETCH_ERROR,
        payload: error?.response?.data?.message || 'Something went wrong',
      });

      return error.response.data;
    }
  };

  const updateProfile = async (params) => {
    dispatch({type: FETCH_START});
    try {
      const res = await jwtAxios.put(UPDATE_PROFILE, params);

      if (res.data.status === 1) {
        const profileRes = await jwtAxios.get(PROFILE);
        setJWTAuthData({
          user: profileRes.data.data,
          isAuthenticated: true,
          isLoading: false,
        });

        dispatch({
          type: SHOW_MESSAGE,
          payload: res.data.message,
          payloadType: 'success',
        });
      } else {
        dispatch({
          type: FETCH_ERROR,
          payload: res.data.message || 'Something went wrong',
        });
      }

      return res.data;
    } catch (error) {
      dispatch({
        type: FETCH_ERROR,
        payload: error?.response?.data?.message || 'Something went wrong',
      });

      return error.response.data;
    }
  };

  const changePassword = async (params) => {
    dispatch({type: FETCH_START});
    try {
      const res = await jwtAxios.put(CHANGE_PASSWORD, params);

      if (res.data.status === 1) {
        dispatch({
          type: SHOW_MESSAGE,
          payload: res.data.message,
          payloadType: 'success',
        });
      } else {
        dispatch({
          type: FETCH_ERROR,
          payload: res.data.message || 'Something went wrong',
        });
      }

      return res.data;
    } catch (error) {
      dispatch({
        type: FETCH_ERROR,
        payload: error?.response?.data?.message || 'Something went wrong',
      });

      return error.response.data;
    }
  };

  const resetRedirect = async () => {
    setJWTAuthData({
      ...firebaseData,
      redirectParams: null,
      redirectPath: null,
    });
  };

  return (
    <JWTAuthContext.Provider
      value={{
        ...firebaseData,
      }}
    >
      <JWTAuthActionsContext.Provider
        value={{
          resetRedirect,
          signUpUser,
          signInUser,
          logout,
          forgotPassword,
          resetPassword,
          updateProfile,
          changePassword,
        }}
      >
        {children}
      </JWTAuthActionsContext.Provider>
    </JWTAuthContext.Provider>
  );
};
export default JWTAuthAuthProvider;

JWTAuthAuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
