import React from 'react';

import FullCalendar from '@fullcalendar/react';
import DayGridPlugin from '@fullcalendar/daygrid';
import InteractionPlugin from '@fullcalendar/interaction';

import PropTypes from 'prop-types';

import {CalenderContainer} from './index.style';

const Calender = ({onDateClick, onEventClick, sx, topComponent, ...props}) => {
  return (
    <>
      {typeof topComponent === 'function' ? topComponent() : topComponent}

      <CalenderContainer sx={sx}>
        <FullCalendar
          plugins={[DayGridPlugin, InteractionPlugin]}
          initialView='dayGridMonth'
          selectable={true}
          height={'auto'}
          {...props}
          dateClick={onDateClick}
          eventClick={onEventClick}
          headerToolbar={{
            left: 'prev',
            center: 'title',
            right: 'next',
            ...(props?.headerToolbar ?? {}),
          }}
        />
      </CalenderContainer>
    </>
  );
};

export default Calender;

Calender.propTypes = {
  sx: PropTypes.string,
  onDateClick: PropTypes.func,
  onEventClick: PropTypes.func,
  headerToolbar: PropTypes.object,
  topComponent: PropTypes.any,
};
