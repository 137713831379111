import React from 'react';

import {
  FaMapMarkerAlt,
  FaPhoneAlt,
  FaFacebookF,
  FaTwitter,
  FaInstagram,
  FaHome,
  FaListOl,
  FaDollarSign,
  FaCheckDouble,
  FaBook,
  FaTicketAlt,
} from 'react-icons/fa';

import routeUrl from 'shared/constants/RouteUrl';

const routesConfig = [
  {
    id: 'page-dashboard',
    title: 'Dashboard',
    messageId: 'sidebar.menu.dashboard',
    type: 'item',
    icon: <FaHome />,
    url: routeUrl.dashboard,
  },
  {
    id: 'group-order',
    title: 'Order',
    messageId: 'sidebar.menu.order',
    icon: <FaListOl />,
    type: 'collapse',
    children: [
      {
        id: 'page-order-list',
        title: 'Order List',
        messageId: 'sidebar.menu.orderList',
        type: 'item',
        icon: <FaListOl />,
        url: routeUrl.order,
      },
      {
        id: 'page-payment',
        title: 'Payment Information',
        messageId: 'sidebar.menu.payment',
        type: 'item',
        icon: <FaDollarSign />,
        url: routeUrl.payment,
      },
    ],
  },
  {
    id: 'page-claim',
    title: 'Claim',
    messageId: 'sidebar.menu.claim',
    type: 'item',
    icon: <FaCheckDouble />,
    url: routeUrl.claim,
  },
  {
    id: 'page-ticket',
    title: 'Hi UNICA',
    messageId: 'sidebar.menu.ticket',
    type: 'item',
    icon: <FaTicketAlt />,
    url: routeUrl.ticket,
  },
  {
    id: 'page-report',
    title: 'History',
    messageId: 'sidebar.menu.history',
    type: 'item',
    icon: <FaBook />,
    url: routeUrl.history,
  },
];

export const publicRoutesConfig = [
  {
    title: 'public-header.main',
    destination: 'main',
  },
  {
    title: 'public-header.about',
    destination: 'about',
  },
  {
    title: 'public-header.service',
    destination: 'service',
  },
  {
    title: 'public-header.contact',
    destination: 'contact',
  },
];

export const footerListInfos = [
  {
    icon: <FaMapMarkerAlt />,
    text: (
      <>
        {
          'Jl. RE Martadinata, Komp. Ruko Permata Anccol Blok M-31 - Jakarta Utara'
        }
        <br />
        <br />
        {'Jl. Teuku Umar, Komp. Pontianak Mall Blok B No.2 - Pontianak'}
      </>
    ),
  },
  {
    icon: <FaPhoneAlt />,
    text: (
      <>
        {'021 - 6456565, 647160557, 64717871'}
        <br />
        <br />
        {'0561 - 769846'}
      </>
    ),
  },
];

export const footerListSosmed = [
  {
    icon: <FaFacebookF />,
    link: '#',
  },
  {
    icon: <FaTwitter />,
    link: '#',
  },
  {
    icon: <FaInstagram />,
    link: '#',
  },
];

export default routesConfig;
