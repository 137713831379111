import React from 'react';

import {useDropzone} from 'react-dropzone';
import {useField} from 'formik';

import PropTypes from 'prop-types';

import {Button} from '@mui/material';

const UploadButton = ({children, multiple, disabled, ...props}) => {
  const [field, , helper] = useField(props);

  const {getRootProps, getInputProps} = useDropzone({
    accept: {
      'image/*': [],
      'application/pdf': [],
    },
    multiple: multiple ?? false,
    disabled,
    onDrop: (acceptedFiles) => {
      helper.setValue([
        ...(multiple ? field?.value ?? [] : []),
        ...acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          }),
        ),
      ]);
    },
  });

  return (
    <Button
      sx={{
        minWidth: 0,
        borderRadius: '100%',
        color: '#000',
      }}
      {...getRootProps({className: 'dropzone'})}
    >
      {children}
      <input name={field.name} {...getInputProps()} />
    </Button>
  );
};

export default UploadButton;

UploadButton.propTypes = {
  children: PropTypes.node,
  multiple: PropTypes.bool,
  disabled: PropTypes.bool,
};
