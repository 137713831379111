import React from 'react';

import {useField} from 'formik';
import AsyncSelect from 'react-select/async';
import AsyncCreatableSelect from 'react-select/async-creatable';
import PropTypes from 'prop-types';

import {FormControl, FormHelperText} from '@mui/material';

import {grey, red} from '@mui/material/colors';

const SelectAsync = ({
  label,
  fetchOptions,
  defaultValue,
  onChange,
  disabled,
  isCreatable,
  ...props
}) => {
  const [field, meta, helper] = useField(props);
  const errorText = meta.error && meta.touched ? meta.error : '';

  const BaseSelect = isCreatable ? AsyncCreatableSelect : AsyncSelect;

  const filterDatas = (data, inputValue) => {
    return data.filter((i) =>
      i.label.toLowerCase().includes(inputValue.toLowerCase()),
    );
  };

  React.useEffect(() => {
    if (defaultValue) {
      helper.setValue(defaultValue);
    }
  }, []);

  const loadOptions = (inputValue, callback) => {
    if (inputValue.length === 0) {
      callback([]);
      return;
    }

    if (typeof fetchOptions === 'function')
      fetchOptions(inputValue, (data) =>
        callback(filterDatas(data ?? [], inputValue)),
      );
  };

  const handleOnChange = (value) => {
    helper.setValue(value);

    if (typeof onChange === 'function') {
      onChange(value);
    }
  };

  const additionalProps = isCreatable
    ? {formatCreateLabel: (value) => `Buat "${value}"`}
    : {};

  return (
    <FormControl sx={{flex: 1, width: '100%'}} fullWidth error={!!errorText}>
      <BaseSelect
        {...props}
        {...additionalProps}
        name={field.name}
        value={typeof field.value === 'object' ? field.value : defaultValue}
        onChange={handleOnChange}
        cacheOptions
        loadOptions={loadOptions}
        defaultValue={defaultValue}
        isDisabled={disabled}
        placeholder={label}
        styles={{
          control: (baseStyles) => ({
            ...baseStyles,
            borderColor: !errorText ? grey[300] : red[300],
            height: 54,
          }),
        }}
      />

      {!!errorText && <FormHelperText sx={{ml: 0}}>{errorText}</FormHelperText>}
    </FormControl>
  );
};

export default SelectAsync;

SelectAsync.propTypes = {
  label: PropTypes.string,
  fetchOptions: PropTypes.func,
  onChange: PropTypes.func,
  defaultValue: PropTypes.object,
  disabled: PropTypes.bool,
  isCreatable: PropTypes.bool,
};
