const moneyFormat = (value = '') => {
  const [nominal, comma] = value.toString().split('.');

  return (
    nominal
      .split('')
      .reverse()
      .reduce(function (acc, num, i) {
        return num + (num != '-' && i && !(i % 3) ? '.' : '') + acc;
      }, '') + (comma ? ',' + comma : '')
  );
};

export default moneyFormat;
