import React from 'react';

import {NavLink, useNavigate} from 'react-router-dom';
import {scroller} from 'react-scroll';

import {ListItem, List, Box, Typography, styled} from '@mui/material';

import FooterWrapper from './FooterWrapper';

import IntlMessages from '../../../../utility/IntlMessages';
import {useLayoutContext} from '../../../../utility/AppContextProvider/LayoutContextProvider';
import Logo from '../../../../../assets/logo/unica-logo-white.png';

import routeUrl from 'shared/constants/RouteUrl';
import {
  publicRoutesConfig,
  footerListInfos,
  footerListSosmed,
} from '../../../../../pages/routesConfig';

const StyledNavLink = styled(NavLink)(() => ({}));

const otherFooterRoutes = [
  {
    title: 'public-header.privasi',
    destination: routeUrl.privacy,
    switchPage: true,
  },
  {
    title: 'public-header.term',
    destination: routeUrl.term,
    switchPage: true,
  },
];

const AppFooter = () => {
  const {footer, footerType, navStyle} = useLayoutContext();
  const navigate = useNavigate();

  const handleOnLinkPress = ({destination}) => {
    return () => {
      if (window.location.pathname !== '/') {
        navigate(routeUrl.home, {
          state: {destination},
        });
        return;
      }

      scroller.scrollTo(destination, {
        duration: 500,
        smooth: true,
        offset: -60,
      });
    };
  };

  const renderFooterInfo = (item, index) => {
    return (
      <ListItem
        key={`info-${index}`}
        sx={{
          p: 0,
          mb: 5,
          maxWidth: {md: '60%'},
          display: 'flex',
          alignItems: 'flex-start',
        }}
      >
        <Box sx={{mt: 0.8, fontSize: {xs: 12, sm: 14}, mr: {xs: 3, sm: 5}}}>
          {item.icon}
        </Box>
        <Typography sx={{fontSize: {xs: 12, sm: 14}}}>{item.text}</Typography>
      </ListItem>
    );
  };

  const renderFooterSosmed = (item, index) => {
    return (
      <ListItem key={`sosmed-${index}`} sx={{p: 0}}>
        <Box sx={{fontSize: 18, ml: {xs: 0, md: 5}, mr: {xs: 5, md: 0}}}>
          {item.icon}
        </Box>
      </ListItem>
    );
  };

  const renderFooterNav = (item, index) => {
    if (index === 0) return;

    if (item?.switchPage) {
      return (
        <li key={`link-${index}`}>
          <StyledNavLink
            to={item.destination}
            sx={{
              opacity: 0.7,
              display: 'block',
              ml: {xs: 0, md: 10},
              color: (theme) => theme.palette.background.paper,
              cursor: 'pointer',
              textDecoration: 'none',
              ':hover': {opacity: 1},
            }}
          >
            <Typography sx={{fontSize: {xs: 12, sm: 14}, m: 0}}>
              <IntlMessages id={item.title} />
            </Typography>
          </StyledNavLink>
        </li>
      );
    }

    return (
      <li key={`link-${index}`}>
        <Box
          onClick={handleOnLinkPress(item)}
          sx={{
            px: 0,
            pl: {xs: 0, md: 10},
            pr: {xs: 10, md: 0},
            opacity: 0.7,
            py: {xs: 2, md: 0},
            color: (theme) => theme.palette.background.paper,
            cursor: 'pointer',
            ':hover': {opacity: 1},
          }}
        >
          <Typography sx={{fontSize: {xs: 12, sm: 14}, m: 0}}>
            <IntlMessages id={item.title} />
          </Typography>
        </Box>
      </li>
    );
  };

  return (
    <>
      {footer &&
      footerType === 'fluid' &&
      navStyle !== 'h-default' &&
      navStyle !== 'hor-light-nav' &&
      navStyle !== 'hor-dark-layout' ? (
        <FooterWrapper className='footer'>
          <NavLink to={routeUrl.home} style={{marginRight: 16}}>
            <img src={Logo} style={{maxHeight: 64, verticalAlign: 'middle'}} />
          </NavLink>

          <div className='footerContainer'>
            <List sx={{flex: 1}}>{footerListInfos.map(renderFooterInfo)}</List>

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: {xs: 'flex-start', md: 'flex-end'},
              }}
            >
              <List
                className='navbarNav'
                sx={{
                  py: 0,
                  display: 'flex',
                  mb: 4,
                  mt: 2,
                  flexDirection: {xs: 'column', sm: 'row'},
                }}
              >
                {[...publicRoutesConfig, ...otherFooterRoutes].map(
                  renderFooterNav,
                )}
              </List>
              <List
                sx={{
                  flexDirection: 'row',
                  display: 'flex',
                  width: 'fit-content',
                  mb: 4,
                }}
              >
                {footerListSosmed.map(renderFooterSosmed)}
              </List>
              <Typography sx={{fontWeight: 'bold'}}>
                © {new Date().getFullYear()} UNICA
              </Typography>
            </Box>
          </div>
        </FooterWrapper>
      ) : null}
    </>
  );
};

export default AppFooter;
