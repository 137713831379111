import idMessages from '../locales/id_ID.json';
import {idID} from '@mui/material/locale';

const IdLang = {
  messages: {
    ...idMessages,
  },
  muiLocale: idID,
  locale: 'id-ID',
};
export default IdLang;
