import React from 'react';

import PropTypes from 'prop-types';
import {Form, Formik} from 'formik';
import {useSelector, useDispatch} from 'react-redux';

import {
  Typography,
  Stack,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Divider,
} from '@mui/material';

import {Dropzone, TextField, RadioField} from 'components';
import {PAYMENT_METHOD} from 'shared/constants/AppDataConst';
import {grey} from '@mui/material/colors';
import {PAYMENT_VALIDATION} from 'shared/validationShema';
import {moneyFormat} from 'utils';
import {onGetPaymentData} from 'redux/actions';

import {ReactComponent as Loading} from '../../../assets/icon/loading.svg';

const Alert = ({onSubmit}, ref) => {
  const dispatch = useDispatch();

  const isLoading = useSelector(({common}) => common.loading);
  const paymentList = useSelector(({payment}) => payment.paymentList);
  const paymentListData = paymentList?.rows ?? [];

  const totalPayment = paymentListData.reduce((accu, items) => {
    if (items?.status !== 1) return accu;

    return accu + (items?.total_payment ?? 0);
  }, 0);
  const files = paymentListData.reduce((accu, items) => {
    return [...accu, items?.file ?? ''];
  }, []);

  const [config, setConfig] = React.useState({
    id: null,
    price: null,
    show: false,
    viewOnly: false,
  });

  React.useImperativeHandle(ref, () => ({
    show: async (newConfig) => {
      if (newConfig.viewOnly) {
        await dispatch(onGetPaymentData(newConfig.id));
      }

      setConfig({...newConfig, show: true});
    },
    hide: handleClose,
  }));

  const handleClose = () => {
    setConfig((prev) => ({...prev, show: false}));
  };

  const handleOnSubmit = (data) => {
    if (typeof onSubmit === 'function') onSubmit(config, data);
  };

  return (
    <Dialog onClose={handleClose} open={config.show} fullWidth={true}>
      <DialogTitle
        sx={{
          fontSize: 16,
          px: 5,
          fontWeight: 600,
          borderBottom: 1,
          borderColor: grey[300],
        }}
      >
        {config.viewOnly ? 'Detail Pembayaran' : 'Pembayaran'}
      </DialogTitle>

      {isLoading ? (
        <DialogContent sx={{p: 0}}>
          <Stack sx={{p: 4, justifyContent: 'center', alignItems: 'center'}}>
            <Loading width={40} height={40} />
          </Stack>
        </DialogContent>
      ) : (
        <Formik
          validateOnChange={true}
          onSubmit={handleOnSubmit}
          onReset={handleClose}
          validationSchema={PAYMENT_VALIDATION}
          initialValues={{
            payment_method: 1,
            total_payment: config.viewOnly ? totalPayment : '',
            file: config.viewOnly ? files : [],
          }}
        >
          {() => (
            <Form sx={{textAlign: 'left'}} noValidate autoComplete='off'>
              <DialogContent sx={{p: 0}}>
                <Stack sx={{p: 5}}>
                  <Stack spacing={2}>
                    <Typography component='label'>Nominal Tagihan</Typography>

                    <TextField
                      startAdornment='Rp.'
                      name='nominal'
                      disabled={true}
                      value={moneyFormat(Number(config?.price ?? '0'))}
                    />
                  </Stack>
                </Stack>

                <Divider />

                <Stack spacing={4} sx={{p: 5}}>
                  <Stack spacing={2}>
                    <Typography component='label'>Metode Pembayaran</Typography>

                    <RadioField
                      name='payment_method'
                      options={[PAYMENT_METHOD[0]]}
                      disabled={config.viewOnly}
                    />
                  </Stack>
                  <Stack spacing={2}>
                    <Typography component='label'>Total Bayar</Typography>

                    <TextField
                      type='number'
                      startAdornment='Rp.'
                      name='total_payment'
                      disabled={config.viewOnly}
                    />
                  </Stack>
                  <Stack spacing={2}>
                    <Typography component='label'>Bukti Transfer</Typography>

                    <Dropzone name='file' disabled={config.viewOnly} />
                  </Stack>
                </Stack>
              </DialogContent>
              <DialogActions sx={{px: 5, borderTop: 1, borderColor: grey[300]}}>
                <Button type='reset' color='secondary'>
                  Batal
                </Button>
                <Button type='submit'>Submit</Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      )}
    </Dialog>
  );
};

export default React.forwardRef(Alert);

Alert.propTypes = {
  onSubmit: PropTypes.func,
};
