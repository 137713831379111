import React from 'react';

import PropsTypes from 'prop-types';

import FilterListIcon from '@mui/icons-material/FilterList';
import {
  Box,
  Stack,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import {
  DataGrid as BaseDataGrid,
  GridToolbarQuickFilter,
  useGridApiRef,
} from '@mui/x-data-grid';

import {DUMMY_COLUMNS} from 'shared/constants/DummyData';
import {grey} from '@mui/material/colors';

function CustomNoRowsOverlay() {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        backgroundColor: grey[50],
      }}
    >
      Tidak ada data
    </Box>
  );
}

const FilterToolbarModal = ({data, applyBtnPress}) => {
  const currentFilter = React.useRef({});

  const [open, setOpen] = React.useState(false);
  const [filteredData, setFilteredData] = React.useState({});

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
    setFilteredData(currentFilter.current);
  };

  const handleReset = () => {
    setFilteredData({});
    applyBtnPress({});
    currentFilter.current = {};
    setOpen(false);
  };

  const handleApply = () => {
    setOpen(false);
    applyBtnPress(filteredData);
    currentFilter.current = filteredData;
  };

  const handleOnFilterChange = (filterData) => {
    setFilteredData((prev) => ({
      ...prev,
      [filterData.field]: filterData.value,
    }));
  };

  const renderInput = (item, idx) => {
    const {filterOperators, ...otherItem} = item;
    const {InputComponent, InputComponentProps} = filterOperators[0];

    return (
      <Box
        key={`filter-input-${idx}`}
        sx={{display: 'flex', alignItems: 'center'}}
      >
        <InputComponent
          {...InputComponentProps}
          label={otherItem.headerName}
          item={{...otherItem, value: filteredData[otherItem.field]}}
          applyValue={handleOnFilterChange}
        />
      </Box>
    );
  };

  return (
    <>
      <Button
        variant='text'
        sx={{borderRadius: 1}}
        onClick={handleOpen}
        startIcon={<FilterListIcon />}
      >
        Filter
      </Button>

      <Dialog
        onClose={handleClose}
        open={open}
        sx={{'& .MuiPaper-root': {overflow: 'visible'}}}
      >
        <DialogTitle sx={{fontSize: 16, px: 4}}>Filter</DialogTitle>
        <DialogContent sx={{px: 4, pb: 2, overflow: 'visible'}}>
          <Stack spacing={2}>{data.map(renderInput)}</Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleReset}>Reset</Button>
          <Button onClick={handleApply}>Apply</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const FilterToolbar = ({filterInputs, customFilterEvent, filterSort}) => {
  const withOpe = filterInputs.filter(({filterOperators}) => filterOperators);
  const isHaveDataCanFilter = withOpe.length > 0;

  const sortFilter =
    filterSort.length > 0 ? filterSort.map((item) => withOpe[item]) : withOpe;

  return (
    <Box sx={{p: 2.5, pb: 0, display: 'flex', justifyContent: 'flex-end'}}>
      {isHaveDataCanFilter && (
        <Box sx={{flex: 1, display: 'flex', alignItems: 'center'}}>
          <FilterToolbarModal
            data={sortFilter}
            applyBtnPress={customFilterEvent}
          />
        </Box>
      )}
      <GridToolbarQuickFilter />
    </Box>
  );
};

const DataGrid = ({
  rows,
  columns,
  filterTab,
  filterSort,
  onFilter,
  hideFilter,
  ...props
}) => {
  const apiRef = useGridApiRef();

  const [filter, setFilter] = React.useState({});

  const {options = [], ref} = filterTab ?? {};

  const currentColumns = columns ? columns : DUMMY_COLUMNS;
  const currentRows = rows ? rows : [];

  const borderColor = 'rgba(224, 224, 224, 1)';

  React.useEffect(() => {
    if (typeof onFilter === 'function') onFilter(filter);
  }, [filter]);

  const getDisabledUndefiedColFilter = (unFilteredColumns) => {
    return unFilteredColumns.map((item) =>
      item.filterOperators ? item : {...item, filterable: false},
    );
  };

  const handleCustomFilterEvent = React.useCallback((newFilterData) => {
    setFilter({...newFilterData});
  }, []);

  const handleFilterTabPress = (newTabIndex) => () => {
    setFilter((prev) => ({...prev, [ref]: newTabIndex}));
  };

  const renderFilterTabItem = (item, index) => {
    const isActive = (filter[ref] ?? options[0].value) === item.value;

    return (
      <Button
        onClick={handleFilterTabPress(item.value)}
        sx={{
          backgroundColor: (theme) => theme.palette.primary.contrastText,
          borderRadius: 0,
          px: 5,
          borderTop: `1px solid ${borderColor}`,
          borderBottom: `1px solid ${isActive ? '#fff' : borderColor}`,
          borderLeft: `${index === 0 ? 1 : 0}px solid ${borderColor}`,
          borderRight: `1px solid ${borderColor}`,
          color: 'black',
          borderTopLeftRadius: 4,
          borderTopRightRadius: 4,
          whiteSpace: 'nowrap',
          width: 'auto',
        }}
        key={`btn-${index}`}
      >
        {item.label}
      </Button>
    );
  };

  return (
    <>
      {options.length > 0 && (
        <Box
          sx={{
            display: 'inline-block',
            mb: '-1px',
            zIndex: 1,
            overflowX: 'auto',
            whiteSpace: 'nowrap',
            '::-webkit-scrollbar': {
              height: 10,
            },

            /* Track */
            '::-webkit-scrollbar-track': {
              backgroundColor: grey[50],
              borderRight: 1,
              borderLeft: 1,
              borderColor: borderColor,
            },

            /* Handle */
            '::-webkit-scrollbar-thumb': {
              background: 'rgba(224, 224, 224, 0.5)',
              borderRadius: 10,
            },
          }}
        >
          {options.map(renderFilterTabItem)}
        </Box>
      )}

      <BaseDataGrid
        {...props}
        apiRef={apiRef}
        columns={currentColumns}
        rows={currentRows}
        getRowHeight={() => 'auto'}
        getEstimatedRowHeight={() => 65}
        slots={{
          toolbar: hideFilter ? undefined : FilterToolbar,
          noRowsOverlay: CustomNoRowsOverlay,
        }}
        pageSizeOptions={[10, 25, 50, {value: -1, label: 'All'}]}
        initialState={{
          pagination: {paginationModel: {pageSize: 10}},
        }}
        slotProps={{
          toolbar: {
            filterSort: filterSort ?? [],
            customFilterEvent: handleCustomFilterEvent,
            filterInputs: getDisabledUndefiedColFilter(
              columns ? columns : DUMMY_COLUMNS,
            ),
          },

          filterPanel: {
            filterFormProps: {
              columnInputProps: {
                variant: 'outlined',
                size: 'small',
                disabled: true,
                sx: {
                  mt: 'auto',
                  mr: 2,
                  '.MuiInputBase-root': {borderRadius: 1},
                },
              },
              operatorInputProps: {sx: {display: 'none'}},
              deleteIconProps: {sx: {display: 'none'}},
              valueInputProps: {
                sx: {
                  '.MuiFormControl-root': {m: 0},
                  '.MuiInputBase-root': {borderRadius: 1},
                },
              },
            },
            sx: {'& .MuiDataGrid-filterForm': {p: 4, pt: 5}},
          },
        }}
        sx={{
          '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {py: '10px'},
          backgroundColor: (theme) => theme.palette.primary.contrastText,
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
          '.MuiDataGrid-columnHeader, .MuiDataGrid-cell': {
            px: 4,
          },
          ...(props?.sx ?? {}),
        }}
      />
    </>
  );
};

export default DataGrid;

DataGrid.propTypes = {
  rows: PropsTypes.array,
  columns: PropsTypes.array,
  filterTab: PropsTypes.object,
  filterSort: PropsTypes.array,
  onFilter: PropsTypes.func,
  sx: PropsTypes.object,
  hideFilter: PropsTypes.bool,
};

FilterToolbar.propTypes = {
  filterInputs: PropsTypes.array,
  filterSort: PropsTypes.array,
  customFilterEvent: PropsTypes.func,
};

FilterToolbarModal.propTypes = {
  data: PropsTypes.array,
  applyBtnPress: PropsTypes.func,
};
