import React from 'react';

import PropTypes from 'prop-types';

import {FormControl, MenuItem, Select, Box} from '@mui/material';
import {grey} from '@mui/material/colors';
import SelectAsync from 'react-select/async';

const FilterSelectInput = (props) => {
  const {
    item,
    applyValue,
    options = [],
    label,
    isAsync = false,
    ...asyncOpt
  } = props;

  const handleFilterChange = (event) => {
    applyValue({...item, value: isAsync ? event : event.target.value});
  };

  const renderItem = (optData, key) => {
    return (
      <MenuItem key={`key-${key}`} value={optData.value}>
        {optData.label}
      </MenuItem>
    );
  };

  return (
    <FormControl sx={{m: 1}} fullWidth>
      <Box
        component='label'
        sx={{
          fontSize: 12,
          display: 'block',
          textAlign: 'left',
          marginBottom: 2,
        }}
      >
        {label ?? ''}
      </Box>

      {isAsync ? (
        <SelectAsync
          {...asyncOpt}
          onChange={handleFilterChange}
          styles={{control: (baseStyles) => ({...baseStyles, height: 54})}}
        />
      ) : (
        <Select
          value={item?.value ?? ''}
          displayEmpty
          onChange={handleFilterChange}
          sx={{
            '.MuiInputBase-root': {borderRadius: 1},
            '.MuiOutlinedInput-notchedOutline': {borderColor: grey[300]},
          }}
        >
          <MenuItem value=''>Semua</MenuItem>
          {options.map(renderItem)}
        </Select>
      )}
    </FormControl>
  );
};

export default FilterSelectInput;

FilterSelectInput.propTypes = {
  item: PropTypes.object,
  applyValue: PropTypes.func,
  focusElementRef: PropTypes.object,
  options: PropTypes.array,
  label: PropTypes.string,
  isAsync: PropTypes.bool,
};
