import {TERM_DELIVERY} from 'shared/constants/AppDataConst';
import FilterSelectInput from 'components/atoms/FilterSelectInput';

// This custom grid data filter for grid data pro
const TermDeliveryFilter = [
  {
    label: 'Equal',
    value: 'equal',
    getApplyFilterFn: (filterItem) => {
      if (!filterItem.field || !filterItem.value || !filterItem.operator) {
        return null;
      }

      return (params) => {
        return params.value === filterItem.value;
      };
    },
    InputComponent: FilterSelectInput,
    InputComponentProps: {options: TERM_DELIVERY},
    getValueAsString: (value) => value,
  },
];

export default TermDeliveryFilter;
