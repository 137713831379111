import React from 'react';
import {Box, Container} from '@mui/material';
import PropsTypes from 'prop-types';

const FooterWrapper = ({children, ...rest}) => {
  return (
    <Box
      sx={{
        backgroundColor: (theme) => theme.palette.primary.main,
        color: (theme) => theme.palette.primary.contrastText,
        padding: {xs: '3rem 0.75rem', lg: '3.75rem 0'},

        '& .footerContainer': {
          flexDirection: {xs: 'column', md: 'row'},
          display: 'flex',
          mt: 5,
        },
      }}
      {...rest}
    >
      <Container>{children}</Container>
    </Box>
  );
};

export default FooterWrapper;

FooterWrapper.propTypes = {
  children: PropsTypes.node,
};
