import React from 'react';
import {Box} from '@mui/material';
import Logo from '../../../../../assets/logo/unica-logo.png';

const AppLogo = () => {
  return (
    <Box
      sx={{
        height: {xs: 56, sm: 70},
        padding: 2.5,
        display: 'flex',
        flexDirection: 'row',
        cursor: 'pointer',
        alignItems: 'center',
        justifyContent: 'center',
        '& img': {
          height: {xs: 40, sm: 45},
        },
      }}
      className='app-logo'
    >
      <img src={Logo} />
    </Box>
  );
};

export default AppLogo;
