import React from 'react';

import PropsTypes from 'prop-types';
import {useField} from 'formik';

import {
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormLabel,
  FormHelperText,
} from '@mui/material';

const RadioField = ({options, label, onChange, ...props}) => {
  const [field, meta] = useField(props);
  const errorText = meta.error && meta.touched ? meta.error : '';

  const handleOnChange = (event) => {
    if (props.disabled) {
      return;
    }

    if (typeof onChange === 'function') {
      onChange(event);
    }

    field.onChange(event);
  };

  const renderOpt = (item, index) => {
    return (
      <FormControlLabel
        key={`radio-${index}`}
        disabled={props.disabled}
        control={<Radio disabled={item?.disabled} />}
        {...item}
      />
    );
  };

  return (
    <FormControl sx={{flex: 1, width: '100%'}} error={!!errorText}>
      {label && <FormLabel>{label}</FormLabel>}

      <RadioGroup {...field} onChange={handleOnChange} row {...props}>
        {(options ?? []).map(renderOpt)}
      </RadioGroup>

      {!!errorText && <FormHelperText sx={{ml: 0}}>{errorText}</FormHelperText>}
    </FormControl>
  );
};

export default RadioField;

RadioField.propTypes = {
  options: PropsTypes.array,
  label: PropsTypes.string,
  onChange: PropsTypes.func,
  disabled: PropsTypes.bool,
};
