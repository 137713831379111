import React, {useEffect} from 'react';

import {useSelector, useDispatch} from 'react-redux';
import AppContentView from '@crema/core/AppContentView';
import {useAuthUser} from '../../utility/AuthHooks';
import {
  useLayoutActionsContext,
  useLayoutContext,
} from '../../utility/AppContextProvider/LayoutContextProvider';
import AuthWrapper from './AuthWrapper';
import {useUrlSearchParams} from 'use-url-search-params';
import {useSidebarActionsContext} from '../../utility/AppContextProvider/SidebarContextProvider';
import Layouts from './Layouts';

import {Alert} from 'components';

import {HIDE_MESSAGE} from 'shared/constants/ActionTypes';
import {publicRouteConfig} from 'pages/publicPages';
import {useLocation} from 'react-router-dom';

const AppLayout = () => {
  const notificationRef = React.useRef();

  const {navStyle} = useLayoutContext();

  const {error, message, messageType} = useSelector(({common}) => common);
  const dispatch = useDispatch();

  const {isAuthenticated} = useAuthUser();
  const {updateNavStyle} = useLayoutActionsContext();
  const {updateMenuStyle, setSidebarBgImage} = useSidebarActionsContext();
  const AppLayout = Layouts[navStyle];
  const [params] = useUrlSearchParams();

  const {pathname} = useLocation();

  const isPublicPage = publicRouteConfig.find((item) => item.path === pathname);

  useEffect(() => {
    if (params.layout) updateNavStyle(params.layout);
    if (params.menuStyle) updateMenuStyle(params.menuStyle);
    if (params.sidebarImage) setSidebarBgImage(true);
  }, [
    params.layout,
    params.menuStyle,
    params.sidebarImage,
    updateNavStyle,
    updateMenuStyle,
    setSidebarBgImage,
  ]);

  React.useEffect(() => {
    if (messageType !== null && (error.length > 0 || message.length > 0)) {
      notificationRef.current.hide();

      notificationRef.current.show({
        type: messageType,
        msg: message.length > 0 ? message : error,
      });
    }
  }, [error, message, messageType]);

  const handleOnHide = () => {
    dispatch({type: HIDE_MESSAGE});
  };

  return (
    <>
      {!isAuthenticated || isPublicPage ? (
        <AuthWrapper>
          <AppContentView />
        </AuthWrapper>
      ) : (
        <AppLayout />
      )}

      <Alert ref={notificationRef} onHide={handleOnHide} />
    </>
  );
};

export default React.memo(AppLayout);
