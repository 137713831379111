import React from 'react';

import PropTypes from 'prop-types';

import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';

const ShipModal = (
  {onSubmit, onCancel, submitText, cancelText, title, desc},
  ref,
) => {
  const tempData = React.useRef();

  const [open, setOpen] = React.useState(false);

  React.useImperativeHandle(ref, () => ({
    show: handleShowModal,
    hide: handleCancel,
  }));

  const handleShowModal = (newTempData) => {
    tempData.current = newTempData;

    setOpen(true);
  };

  const handleCancel = () => {
    tempData.current = null;

    setOpen(false);

    if (typeof onCancel === 'function') {
      onCancel();
    }
  };

  const handleSubmit = () => {
    setOpen(false);

    if (typeof onSubmit === 'function') {
      onSubmit(tempData.current);
    }
  };

  return (
    <Dialog open={open} fullWidth={true}>
      <DialogTitle sx={{fontSize: 16, px: 4}}>{title ?? 'Alert'}</DialogTitle>
      <DialogContent sx={{px: 4, pb: 2}}>{desc}</DialogContent>
      <DialogActions sx={{px: 4, pb: 4}}>
        <Button color='warning' onClick={handleCancel}>
          {cancelText ?? 'Batal'}
        </Button>
        <Button onClick={handleSubmit} variant='contained' color='secondary'>
          {submitText ?? 'Iya'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default React.forwardRef(ShipModal);

ShipModal.propTypes = {
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  submitText: PropTypes.string,
  cancelText: PropTypes.string,
  title: PropTypes.string,
  desc: PropTypes.string,
};
