export const DUMMY_ROWS = [
  {id: 1, lastName: 'Snow', firstName: 'Jon', age: 35},
  {id: 2, lastName: 'Lannister', firstName: 'Cersei', age: 42},
  {id: 3, lastName: 'Lannister', firstName: 'Jaime', age: 45},
  {id: 4, lastName: 'Stark', firstName: 'Arya', age: 16},
  {id: 5, lastName: 'Targaryen', firstName: 'Daenerys', age: null},
  {id: 6, lastName: 'Melisandre', firstName: null, age: 150},
  {id: 7, lastName: 'Clifford', firstName: 'Ferrara', age: 44},
  {id: 8, lastName: 'Frances', firstName: 'Rossini', age: 36},
  {id: 9, lastName: 'Roxie', firstName: 'Harvey', age: 65},
];

export const DUMMY_COLUMNS = [
  {field: 'id', headerName: 'ID', width: 90},
  {
    field: 'firstName',
    headerName: 'First name',
    width: 150,
    editable: true,
  },
  {
    field: 'lastName',
    headerName: 'Last name',
    width: 150,
    editable: true,
  },
  {
    field: 'age',
    headerName: 'Age',
    type: 'number',
    width: 110,
    editable: true,
  },
];

export const DUMMY_ROWS_ORDER = [
  {
    id: 1,
    noOrder: 'UN001',
    date: '12 Maret 2023',
    sender: 'PT ABC',
    receiver: 'PT DEF',
    typeDelivery: 'FCL',
    termDelivery: 'DTD',
    routeDelivery: 'Jakarta - Pontianak',
    statusDelivery: 'Muat Kapal',
    statusPayment: 'Sudah bayar',
  },
  {
    id: 2,
    noOrder: 'UN001',
    date: '12 Maret 2023',
    sender: 'PT ABC',
    receiver: 'PT DEF',
    typeDelivery: 'FCL',
    termDelivery: 'DTD',
    routeDelivery: 'Jakarta - Pontianak',
    statusDelivery: 'Muat Kapal',
    statusPayment: 'Sudah bayar',
  },
  {
    id: 3,
    noOrder: 'UN001',
    date: '12 Maret 2023',
    sender: 'PT ABC',
    receiver: 'PT DEF',
    typeDelivery: 'FCL',
    termDelivery: 'DTD',
    routeDelivery: 'Jakarta - Pontianak',
    statusDelivery: 'Muat Kapal',
    statusPayment: 'Sudah bayar',
  },
  {
    id: 4,
    noOrder: 'UN001',
    date: '12 Maret 2023',
    sender: 'PT ABC',
    receiver: 'PT DEF',
    typeDelivery: 'FCL',
    termDelivery: 'DTD',
    routeDelivery: 'Jakarta - Pontianak',
    statusDelivery: 'Muat Kapal',
    statusPayment: 'Sudah bayar',
  },
];

export const DUMMY_ROWS_PAYMENT = [
  {
    id: 1,
    noOrder: 'UN001',
    date: '12 Maret 2023',
    sender: 'PT ABC',
    receiver: 'PT DEF',
    typeDelivery: 'FCL',
    termDelivery: 'DTD',
    routeDelivery: 'Jakarta - Pontianak',
    payment: 'Rp. 11.000.000',
    statusPayment: 'Sudah bayar',
  },
  {
    id: 2,
    noOrder: 'UN001',
    date: '12 Maret 2023',
    sender: 'PT ABC',
    receiver: 'PT DEF',
    typeDelivery: 'FCL',
    termDelivery: 'DTD',
    routeDelivery: 'Jakarta - Pontianak',
    payment: 'Rp. 11.000.000',
    statusPayment: 'Sudah bayar',
  },
  {
    id: 3,
    noOrder: 'UN001',
    date: '12 Maret 2023',
    sender: 'PT ABC',
    receiver: 'PT DEF',
    typeDelivery: 'FCL',
    termDelivery: 'DTD',
    routeDelivery: 'Jakarta - Pontianak',
    payment: 'Rp. 11.000.000',
    statusPayment: 'Sudah bayar',
  },
  {
    id: 4,
    noOrder: 'UN001',
    date: '12 Maret 2023',
    sender: 'PT ABC',
    receiver: 'PT DEF',
    typeDelivery: 'FCL',
    termDelivery: 'DTD',
    routeDelivery: 'Jakarta - Pontianak',
    payment: 'Rp. 11.000.000',
    statusPayment: 'Sudah bayar',
  },
];

export const DUMMY_ROWS_CLAIM = [
  {
    id: 1,
    noClaim: 'UN001',
    date: '12 Maret 2023',
    noOrder: 'UN001',
    sender: 'PT ABC',
    receiver: 'PT DEF',
    typeDelivery: 'FCL',
    termDelivery: 'DTD',
    routeDelivery: 'Jakarta - Pontianak',
    topic: 'Barang Rusak',
    statusPayment: 'Sudah bayar',
  },
  {
    id: 2,
    noClaim: 'UN001',
    date: '12 Maret 2023',
    noOrder: 'UN001',
    sender: 'PT ABC',
    receiver: 'PT DEF',
    typeDelivery: 'FCL',
    termDelivery: 'DTD',
    routeDelivery: 'Jakarta - Pontianak',
    topic: 'Barang Rusak',
    statusPayment: 'Sudah bayar',
  },
  {
    id: 3,
    noClaim: 'UN001',
    date: '12 Maret 2023',
    noOrder: 'UN001',
    sender: 'PT ABC',
    receiver: 'PT DEF',
    typeDelivery: 'FCL',
    termDelivery: 'DTD',
    routeDelivery: 'Jakarta - Pontianak',
    topic: 'Barang Rusak',
    statusPayment: 'Sudah bayar',
  },
  {
    id: 4,
    noClaim: 'UN001',
    date: '12 Maret 2023',
    noOrder: 'UN001',
    sender: 'PT ABC',
    receiver: 'PT DEF',
    typeDelivery: 'FCL',
    termDelivery: 'DTD',
    routeDelivery: 'Jakarta - Pontianak',
    topic: 'Barang Rusak',
    statusPayment: 'Sudah bayar',
  },
];

export const DUMMY_ROWS_REPORT = [
  {
    id: 1,
    noOrder: 'UN001',
    noInvoice: 'UN001',
    sender: 'PT ABC',
    receiver: 'PT DEF',
    routeDelivery: 'Jakarta - Pontianak',
    statusDelivery: 'Selesai',
    statusPayment: 'Sudah bayar',
  },
  {
    id: 2,
    noOrder: 'UN001',
    noInvoice: 'UN001',
    sender: 'PT ABC',
    receiver: 'PT DEF',
    routeDelivery: 'Jakarta - Pontianak',
    statusDelivery: 'Selesai',
    statusPayment: 'Sudah bayar',
  },
  {
    id: 3,
    noOrder: 'UN001',
    noInvoice: 'UN001',
    sender: 'PT ABC',
    receiver: 'PT DEF',
    routeDelivery: 'Jakarta - Pontianak',
    statusDelivery: 'Selesai',
    statusPayment: 'Sudah bayar',
  },
  {
    id: 4,
    noOrder: 'UN001',
    noInvoice: 'UN001',
    sender: 'PT ABC',
    receiver: 'PT DEF',
    routeDelivery: 'Jakarta - Pontianak',
    statusDelivery: 'Selesai',
    statusPayment: 'Sudah bayar',
  },
];
